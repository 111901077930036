import { ComponentPortal, ComponentType, Portal, TemplatePortal } from '@angular/cdk/portal';
import {
  Injectable,
  Injector,
  TemplateRef,
  ViewContainerRef,
  EventEmitter,
  InjectionToken,
  inject,
} from '@angular/core';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material/sidenav';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  static CONTAINER_DATA = new InjectionToken<Record<string, unknown>>('CONTAINER_DATA');

  /**
   * Notify if a modification has been made in the sidenav and data need to be refresh
   */
  needRefresh = new EventEmitter<boolean>();

  /**
   * The sidenav attached to this service used to display the portal content.
   * Need to be set using {@see attachedSidenav}
   */
  attachedSidenav!: MatSidenav;

  /**
   * The portal content for the sidenav
   */
  portal$ = new Subject<Portal<unknown> | null>();

  /**
   * Control if the close using backdrop is enabled or not
   */
  private autoCloseEnabled!: boolean;
  private injector = inject(Injector);

  /**
   * Build a portal template
   */
  static buildPortalTemplateRef<C = unknown>(
    templateRef: TemplateRef<C>,
    vcr: ViewContainerRef,
    context?: C,
  ): TemplatePortal<C> {
    return new TemplatePortal<C>(templateRef, vcr, context);
  }

  /**
   * Build a portal using a component
   */
  static buildPortalComponent<T = unknown>(
    component: ComponentType<T>,
    viewContainerRef?: ViewContainerRef | null,
    injector?: Injector | null,
  ) {
    return new ComponentPortal<T>(component, viewContainerRef, injector);
  }

  /**
   * @description Open sidenav with input parameters.
   * Injector replace @Input fields since those can't be used inside portal attached component
   * @param component Component you want to open in a sidenav
   * @param data Data you want to pass to the previously given component. Replace @Input
   * @param vcr ViewContainerRef who represents where attach component
   * @see Injector
   * @return Promise will be completed when component is attached
   */
  openSidenavInputs<T = unknown>(
    component: ComponentType<T>,
    data: Record<string, unknown>,
    vcr?: ViewContainerRef | null,
  ): Promise<MatDrawerToggleResult> {
    return this.openSidenav(SidenavService.buildPortalComponent(component, vcr, this.createInjector(data)));
  }

  createInjector(inputData: Record<string, unknown>): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: SidenavService.CONTAINER_DATA, useValue: inputData }],
    });
  }

  /**
   * Sets the panel portal to the specified portal
   */
  setPortal<T>(portal: Portal<T>, autoCloseEnabled = true) {
    this.autoCloseEnabled = autoCloseEnabled;
    this.portal$.next(portal);
  }

  /**
   * Set the portal content using template ref
   */
  setPortalTemplateRef<T = unknown>(templateRef: TemplateRef<T>, vcr: ViewContainerRef, context?: T) {
    this.portal$.next(SidenavService.buildPortalTemplateRef<T>(templateRef, vcr, context));
  }

  /**
   * Opens the sidenav with optionally a portal to be set
   */
  openSidenav(portal?: Portal<unknown>, autoCloseEnabled?: boolean) {
    if (portal) {
      this.setPortal(portal, autoCloseEnabled);
    }
    return this.attachedSidenav.open();
  }

  /**
   * Closes the sidenav
   */
  closeSidenav(): Promise<MatDrawerToggleResult> | void {
    if (this.autoCloseEnabled) {
      return this.attachedSidenav.close();
    }
  }

  /**
   * Emit refresh data event
   */
  emitNeedRefresh(value = true) {
    this.needRefresh.next(value);
  }
}
