import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { TooltipCardComponent } from './components/tooltip-card/tooltip-card.component';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LayoutSplitComponent } from './components/layout-split/layout-split.component';
import { TooltipAdvertisingComponent } from './components/tooltip-advertising/tooltip-advertising.component';
import { LoadingComponent } from './components/loading/loading.component';
import { TranslateModule } from './translate.module';
import { ExtendedModule, FlexModule, GridModule } from '@ngbracket/ngx-layout';
import { LayoutCardComponent } from './components/layout-card/layout-card.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { MixpanelTrackDirective } from '@movinmotion/data-access-third-party';
import { WrapFnPipe } from '@movinmotion/util-ngx';

const SHARED_COMPONENTS = [
  TooltipCardComponent,
  CustomInputComponent,
  LayoutSplitComponent,
  LayoutCardComponent,
  TooltipAdvertisingComponent,
  LoadingComponent,
  OtpInputComponent,
];

const SHARED_PIPES = [];

const SHARED_DIRECTIVES = [];

const SHARED = [...SHARED_COMPONENTS, ...SHARED_PIPES, ...SHARED_DIRECTIVES];

@NgModule({
  declarations: SHARED,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    FlexModule,
    GridModule,
    ExtendedModule,
    MixpanelTrackDirective,
    WrapFnPipe,
  ],
  exports: SHARED,
})
export class SharedModule {}
