import { Component, Input } from '@angular/core';
import { THEMES } from '../tooltip-card/tooltip-card.component';
import { AppDisplayService } from '../../../core/services/app-display.service';
import { AppDisplay } from '../../../core/services/app-display.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'mm-layout-split',
  templateUrl: './layout-split.component.html',
  styleUrls: ['./layout-split.component.scss'],
  standalone: false,
})
export class LayoutSplitComponent {
  @Input() loading = false;
  @Input() disclaimer = false;

  disclaimerTooltipTheme = THEMES.blue;

  availableAppDisplay = AppDisplay;

  filmFranceSubscriptionLinks = environment.filmFranceSubscribeUrls;

  constructor(public appDisplay: AppDisplayService) {}

  /**
   * Manage the layout grid with the 3 elements:
   *  - header: with the logo and disclaimer
   *  - left: main part (login for example)
   *  - right: advertising (for example subscription links)
   *
   * Film france case:
   *     header right
   *     left   right
   *
   * For all other cases:
   *     header empty
   *     left   right
   */
  getGdAreasFromAppDisplay(display: AppDisplay | string): string {
    const partSize = [1, 2, 3, 4, 5];
    const header = partSize.map(() => 'header').join(' ');
    const left = partSize.map(() => 'left').join(' ');
    const right = partSize.map(() => 'right').join(' ');
    const empty = partSize.map(() => '.').join(' ');
    const separator = ' . . ';
    if (display === AppDisplay.filmFranceTalents) {
      return header + separator + right + ' | ' + left + separator + right;
    }
    return header + separator + empty + ' | ' + left + separator + right;
  }
}
