@if (capsLock || numLock) {
  <span class="lock-message">
    <mat-icon>lock</mat-icon>
    @if (capsLock && !numLock) {
      <span>{{ capsLockMessage }}</span>
    }
    @if (capsLock && numLock) {
      <span>{{ allLockMessage }}</span>
    }
    @if (!capsLock && numLock) {
      <span>{{ numLockMessage }}</span>
    }
  </span>
}
