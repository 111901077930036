import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Language } from './shared/models/environment';
import { TranslateService } from '@ngx-translate/core';
import { AppDisplayService } from './core/services/app-display.service';
import { LoggerService } from './core/services/logger.service';
import { AppDisplay } from './core/services/app-display.model';

@UntilDestroy()
@Component({
  selector: 'mm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  languages = Language;
  workerApp = AppDisplay.worker;
  constructor(
    public auth: AuthService,
    public logger: LoggerService,
    public translate: TranslateService,
    public appDisplay: AppDisplayService,
  ) {}

  ngOnInit() {
    this.auth
      .redirectUrlChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(url => this.logger.debug('Current redirect URL:', url));
    this.auth
      .tokenChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(token => this.logger.debug('Current auth token:', token));
  }

  selectLanguage(langage: Language) {
    this.translate.use(langage);
  }
}
