import { AfterViewInit, Component, HostListener, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mm-caps-num-lock-component',
  templateUrl: './caps-num-lock.component.html',
  styleUrls: ['./caps-num-lock.component.scss'],
  imports: [MatIconModule],
})
export class CapsNumLockComponent implements AfterViewInit {
  @Input()
  capsLockMessage: string | undefined;
  @Input()
  numLockMessage: string | undefined;
  @Input()
  allLockMessage: string | undefined;

  capsLock = false;
  numLock = false;

  ngAfterViewInit(): void {
    this.OnKeyboardEvents(new KeyboardEvent('keydown', { keyCode: 39 }));
  }

  /**
   * Events used to update caps and num lock status.
   *
   * On each tap: up and down
   * and on mouse event to initialise the view, the status is only on keyboard event.
   *
   * @param event
   * @constructor
   * @private
   */
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  @HostListener('window:mouseover', ['$event'])
  private OnKeyboardEvents(event: KeyboardEvent): void {
    this.checkCapsLock(event);
  }

  private checkCapsLock(event: KeyboardEvent) {
    if (event.getModifierState) {
      this.capsLock = event.getModifierState('CapsLock');
      this.numLock = event.getModifierState && event.getModifierState('NumLock');
    }
  }
}
