<div class="tooltip-container" [ngStyle]="{ 'background-color': backgroundColor }" [ngClass]="theme">
  @if (icon) {
    <div class="tooltip-icon-container" [ngStyle]="{ 'border-color': backgroundColor }">
      <mat-icon class="tooltip-icon" [svgIcon]="icon"></mat-icon>
      @if (iconFluff) {
        <mat-icon class="tooltip-icon-fluff" svgIcon="mm-fluff"></mat-icon>
      }
    </div>
  }
  @if (title) {
    <h3 class="tooltip-title mat-small-4" [ngStyle]="{ color: titleColor }">
      {{ title }}
    </h3>
  }
  <div class="mat-caption">
    <ng-content></ng-content>
  </div>
  @if (linkUrl) {
    <a
      class="tooltip-link mat-small-2"
      fxLayout="row"
      fxLayoutAlign="start center"
      [routerLink]="linkUrl"
      [ngStyle]="{ color: linkColor }"
    >
      {{ linkText ? linkText : linkUrl }} <mat-icon [svgIcon]="iconLink"></mat-icon>
    </a>
  }
</div>
