@if (appDisplay.getDisplay$() | async) {
  @if (!loading) {
    <div class="layout-card" ngClass.lt-lg="mobile">
      <div class="mm-card" fxLayout="column" fxLayoutGap="20px">
        <div class="header">
          <img class="mm-logo" [src]="appDisplay.getMainLogo$() | async" alt="mm-logo-desktop" />
        </div>
        <div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  } @else {
    <mm-loading></mm-loading>
  }
} @else {
  <mm-loading></mm-loading>
}
