import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { AppDisplayService } from '../../core/services/app-display.service';
import { AppRoutingChangePassword, AppRoutingLogin } from '../../app-routing.model';
import { InputError } from '../../shared/models/input-error';
import { PasswordService } from '../../core/services/password.service';
import { switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { movinMotionApp } from '../../core/services/app-display.model';
import { ssoEmailValidator } from '../../shared/utils/user.utils';
import { MixpanelService } from '@movinmotion/data-access-third-party';

@UntilDestroy()
@Component({
  selector: 'mm-page-password-reset',
  templateUrl: './page-password-reset.component.html',
  styleUrls: ['./page-password-reset.component.scss'],
  standalone: false,
})
export class PagePasswordResetComponent implements OnInit {
  private auth = inject(AuthService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslateService);
  private appDisplayService = inject(AppDisplayService);
  private passwordService = inject(PasswordService);
  private mixpanel = inject(MixpanelService);

  /**
   * Form fields names
   */
  formFieldKeys = {
    email: 'email',
  };

  /**
   * Errors control for the email input
   */
  emailErrors: InputError[] = [
    {
      isError: control => control?.hasError('required'),
      text$: this.translate.stream(_('mm.common.errors.emailRequired')),
    },
    {
      isError: control => control?.hasError('email'),
      text$: this.translate.stream(_('mm.common.errors.invalidEmail')),
    },
  ];

  /**
   * The password reset form
   */
  form = new FormGroup({
    [this.formFieldKeys.email]: new FormControl<string>('', [Validators.required, Validators.email, ssoEmailValidator]),
  });

  /**
   * Handle loading state during submit login
   */
  submitLoading = false;

  /**
   * Handle send reset password link state
   */
  hasSentForgotPasswordMail = false;

  ngOnInit(): void {
    this.appDisplayService
      .setTitle$(this.translate.stream(_('mm.pages.password-reset.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();

    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe(params => {
      if (params.has(environment.queryParamsKeys.email) && params.get(environment.queryParamsKeys.email)) {
        this.form.get(this.formFieldKeys.email).setValue(params.get(environment.queryParamsKeys.email));
        this.form.get(this.formFieldKeys.email).markAsDirty();
      }
    });

    this.form
      .get(this.formFieldKeys.email)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.hasSentForgotPasswordMail = false;
        },
      });
  }

  /**
   * The password reset submit of the form
   */
  submit(): void {
    this.submitLoading = true;
    const queryParams: Params = {};
    const { [this.formFieldKeys.email]: email } = this.form.value;
    if (email) {
      queryParams[environment.queryParamsKeys.email] = this.form.value.email;
    }
    this.mixpanel.track({ eventName: 'Recevoir un code de vérification', sendImmediately: true });
    this.appDisplayService
      .getDisplay$()
      .pipe(
        first(),
        map(application => movinMotionApp[application]),
        switchMap(application => this.passwordService.otpRequest$({ email, application })),
        switchMap(() => this.updateParams({ [environment.queryParamsKeys.email]: email })),
      )
      .subscribe(() => {
        this.submitLoading = false;
        this.router.navigate([AppRoutingChangePassword], { queryParams, queryParamsHandling: 'merge' });
      });
  }

  private updateParams(params: { [key: string]: string }) {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Go back to login page
   */
  goBackToLoginPage(): void {
    const queryParams: Params = {};
    const { [this.formFieldKeys.email]: email } = this.form.value;
    if (email) {
      queryParams[environment.queryParamsKeys.email] = this.form.value.email;
    }
    this.mixpanel.track({ eventName: 'Revenir à la connexion', sendImmediately: true });
    this.router.navigate([AppRoutingLogin], { queryParams, queryParamsHandling: 'merge' }).then();
  }
}
