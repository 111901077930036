import { Component, inject } from '@angular/core';
import { THEMES as TooltipThemes } from '../tooltip-card/tooltip-card.component';
import { environment } from '../../../../environments/environment';
import { MixpanelAuthService } from '../../../core/services/mixpanel-auth.service';

@Component({
  selector: 'mm-tooltip-advertising',
  templateUrl: './tooltip-advertising.component.html',
  styleUrls: ['./tooltip-advertising.component.scss'],
  standalone: false,
})
export class TooltipAdvertisingComponent {
  /**
   * Select theme for blue tooltip
   */
  tooltipTheme = TooltipThemes.blue;

  /**
   * Url for the subscription
   */
  subscribeUrl = environment.subscribeUrl;

  mixpanelAuth = inject(MixpanelAuthService);
  currentAppAllowed = this.mixpanelAuth.currentAppAllowed;
}
