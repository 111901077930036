<mm-layout-card>
  <form class="reset-password-container" [formGroup]="form" (ngSubmit)="submit()">
    <h4 class="title mat-body-4">
      {{ 'mm.pages.change-password.title.modifyPassword' | translate }}
    </h4>

    <p class="subtitle mat-body">
      {{ 'mm.pages.change-password.subtitle' | translate }}
      <span class="subtitle-highlight wrap-block">{{ email }}</span>
    </p>

    <mm-otp-input
      #otpInput
      [otpLength]="6"
      [autofocus]="true"
      [formControlName]="formFieldKeys.code"
      [errors]="codeErrors"
    ></mm-otp-input>

    <div class="resend-code-container">
      <button
        type="button"
        [disabled]="!canResendCode"
        class="resend-code"
        [matTooltip]="
          canResendCode
            ? undefined
            : ('mm.pages.change-password.resendCodeTooltip'
              | translate: { resendCodeDelayInMinutes: resendCodeDelayInMinutes })
        "
        (click)="resendCode()"
        >{{ 'mm.pages.change-password.button.resendCode' | translate }}</button
      >
    </div>

    @if (resentCodesCounter > 0) {
      <div class="sent-code-help-card">
        <mm-tooltip-card icon="mm-check-green" [iconFluff]="false" [backgroundColor]="'#F3FAF3'">
          @if (resentCodesCounter === 1) {
            <p class="card-section">
              <span class="highlight">{{ 'mm.pages.change-password.sent-code-help.first.main' | translate }}</span>
            </p>
            <p class="card-section">
              <span>{{ 'mm.pages.change-password.sent-code-help.first.sub' | translate }}</span>
              <span class="highlight mail-ellipsis">{{ email }}</span>
            </p>
          }
          @if (resentCodesCounter > 1) {
            <p class="card-section">
              <span class="highlight">{{ 'mm.pages.change-password.sent-code-help.second.main' | translate }}</span>
            </p>
            <p class="card-section">
              {{ 'mm.pages.change-password.sent-code-help.second.sub' | translate }}
              <a href="mailto:{{ appDisplayService.getSupportEmail$() | async }}">{{
                appDisplayService.getSupportEmail$() | async
              }}</a>
            </p>
          }
        </mm-tooltip-card>
      </div>
    }

    <div class="buttons-container">
      <button
        mat-stroked-button
        type="button"
        (click)="goBack()"
        color="accent"
        class="mm-compact mm-borderless back-button"
      >
        {{ 'mm.pages.change-password.button.goBack' | translate }}
      </button>
      <button
        mat-stroked-button
        class="send-button"
        [disabled]="!form.valid || form.pristine || submitLoading"
        color="primary"
        type="submit"
      >
        {{ 'mm.pages.change-password.button.submit' | translate }}
        @if (submitLoading) {
          <mat-spinner [diameter]="25"></mat-spinner>
        }
      </button>
    </div>

    <div class="data-disclaimer-card">
      <mm-tooltip-card icon="mm-lock" [iconFluff]="false" [backgroundColor]="'#F3F7FA'">
        <span class="highlight">{{ 'mm.pages.change-password.data-security-disclaimer.main' | translate }}</span>
        <br />
        <span>{{ 'mm.pages.change-password.data-security-disclaimer.sub' | translate }}</span>
      </mm-tooltip-card>
    </div>
  </form>
</mm-layout-card>
