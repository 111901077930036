import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'firebase/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PageLoginComponent } from './pages/login/page-login.component';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material.module';
import { PageSelectAppComponent } from './pages/select-app/page-select-app.component';
import { CoreModule } from './core/core.module';
import { TranslateModule } from './shared/translate.module';
import { ExtendedModule, FlexModule, GridModule } from '@ngbracket/ngx-layout';
import { PagePasswordResetComponent } from './pages/password-reset/page-password-reset.component';
import { PageChangePasswordComponent } from './pages/change-password/page-change-password.component';
import { CapsNumLockComponent } from '@movinmotion/shared-ui';
import { PageNewPasswordComponent } from './pages/new-password/page-new-password.component';
import { PagePasswordChangedComponent } from './pages/password-changed/page-password-changed.component';
import { WrapFnPipe } from '@movinmotion/util-ngx';
import { MixpanelTrackDirective } from '@movinmotion/data-access-third-party';

@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    PageSelectAppComponent,
    PagePasswordResetComponent,
    PageChangePasswordComponent,
    PageNewPasswordComponent,
    PagePasswordChangedComponent,
  ],
  imports: [
    // Common
    BrowserModule,

    // Core & Shared
    CoreModule,
    SharedModule,

    // Routing
    AppRoutingModule,

    // Not featured components imports
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexModule,
    GridModule,
    ExtendedModule,
    CapsNumLockComponent,
    WrapFnPipe,
    MixpanelTrackDirective,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
