<mm-layout-card>
  <form class="reset-password-container" [formGroup]="form" (ngSubmit)="submit()">
    <h4 class="title mat-body-4">
      {{ 'mm.pages.new-password.title.head' | translate }}
    </h4>

    <p class="subtitle mat-body">
      {{ 'mm.pages.new-password.subtitle.1' | translate }}
      <br /><span [innerHTML]="'mm.pages.new-password.subtitle.2' | translate"></span>
    </p>

    <mm-custom-input
      type="email"
      [small]="true"
      [label]="'mm.pages.new-password.password.label' | translate"
      [formControlName]="formFieldKeys.password"
      [hide]="true"
      [maxLength]="64"
      [errors]="asyncErrors"
    >
      <mm-caps-num-lock-component
        CustomInputComponentWarnMessage
        [capsLockMessage]="'mm.shared.caps-num-lock.caps-lock' | translate"
        [allLockMessage]="'mm.shared.caps-num-lock.caps-and-num-lock' | translate"
        [numLockMessage]="'mm.shared.caps-num-lock.num-lock' | translate"
      ></mm-caps-num-lock-component>
    </mm-custom-input>

    <ul class="password-validity">
      @for (error of passwordErrors; track error) {
        <li
          class="password-validity-conidtion"
          [ngClass]="{ 'password-condition-valid': !error.isError(form.get(formFieldKeys.password)) }"
        >
          <mat-icon>{{
            error.isError(form.get(formFieldKeys.password)) ? 'check_circle_outline' : 'check_circle'
          }}</mat-icon>
          {{ error.text$ | async }}
        </li>
      }
    </ul>

    <div class="buttons-container">
      <button
        mat-stroked-button
        type="button"
        (click)="goBackToLoginPage()"
        color="accent"
        class="mm-compact mm-borderless back-to-login-button"
      >
        {{ 'mm.pages.new-password.button.cancel' | translate }}
      </button>
      <button
        mat-stroked-button
        class="send-button"
        [disabled]="!form.valid || form.pristine"
        color="primary"
        type="submit"
      >
        {{ 'mm.pages.new-password.button.modify' | translate }}
        @if (submitLoading) {
          <mat-spinner [diameter]="25"></mat-spinner>
        }
      </button>
    </div>
  </form>
</mm-layout-card>
