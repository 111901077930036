<div class="mm-input-container" [ngClass]="{ disabled: disabled }">
  <mat-form-field class="mm-input-form" appearance="outline">
    <input
      matInput
      class="mm-input-field"
      [id]="id"
      [name]="name"
      [placeholder]="placeholder"
      [value]="value"
      [disabled]="disabled"
      (input)="input($event)"
      (focus)="onFocus()"
      [attr.maxlength]="otpLength"
      type="numeric"
      style="--otpLength: {{ otpLength }}"
    />
  </mat-form-field>

  @for (error of errors; track error) {
    @if (error.isError(getControl()) && (getControl()?.dirty || getControl()?.touched)) {
      <mat-error class="mm-input-error">
        {{ error.text$ | async }}
      </mat-error>
    }
  }
</div>
