<div class="mm-input-container" [ngClass]="{ small: small, disabled: disabled }">
  <label class="mm-label" [for]="id">{{ label }}</label>
  <mat-form-field class="mm-input-form" appearance="outline">
    @if (prefixIcon) {
      <mat-icon matPrefix [svgIcon]="prefixIcon"></mat-icon>
    }
    <input
      matInput
      class="mm-input-field"
      [placeholder]="placeholder"
      [type]="hide ? 'password' : type"
      [id]="id"
      [name]="name"
      [value]="value"
      [disabled]="disabled"
      (input)="input($event)"
      [attr.maxlength]="maxLength"
      (blur)="blur($event)"
    />
    @if (showBtn) {
      <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
    }
    @if ((suffixIcon || disabled) && !isLoading()) {
      <mat-icon matSuffix [svgIcon]="disabled ? disabledIcon : suffixIcon"></mat-icon>
    }
    @if ((!disabled && suffixText) || isLoading()) {
      <span matSuffix class="mm-input-suffix">
        {{ disabled ? '' : suffixText }}
        @if (isLoading()) {
          <mat-spinner [diameter]="25"></mat-spinner>
        }
      </span>
    }
  </mat-form-field>

  @for (error of errors; track error) {
    @if (error.isError(getControl()) && (getControl()?.dirty || getControl()?.touched)) {
      <mat-error class="mm-input-error">
        <mat-icon class="mm-input-error-icon" svgIcon="mm-hexagon"></mat-icon>{{ error.text$ | async }}
      </mat-error>
    }
  }
  <div class="panel-info">
    <ng-content></ng-content>
  </div>
  <ng-content select="[CustomInputComponentWarnMessage]"></ng-content>
</div>
