@if (appDisplay.getDisplay$() | async; as display) {
  @if (!loading) {
    <div class="layout-split" ngClass.lt-lg="mobile">
      <div
        class="mm-container"
        gdGap="20px"
        [gdAreas]="getGdAreasFromAppDisplay(display)"
        gdAreas.lt-lg="header | left | right"
        gdColumns="repeat(12, 1fr)"
        gdColumns.lt-lg="auto"
        gdRows="min-content"
      >
        <div gdArea="header" class="header" [class.disclaimer]="disclaimer" [ngClass]="display">
          <img class="mm-logo" [src]="appDisplay.getMainLogo$() | async" alt="mm-logo-desktop" />
        </div>
        <div gdArea="left">
          <ng-content></ng-content>
        </div>
        <div gdArea="right" class="right">
          @if (display === availableAppDisplay.filmFranceTalents) {
            <div class="film-france-container">
              <h3 class="mat-subtitle-2">{{ 'mm.shared.layout-split.film-france.title' | translate }}</h3>
              <p class="mat-medium-1">
                {{ 'mm.shared.layout-split.film-france.noAccount' | translate }}
              </p>
              <div class="subscription-links">
                <a
                  [mmMixpanelTrack]="'Redirection Technicien Artiste'"
                  [mixpanelTrackingType]="'link'"
                  mat-raised-button
                  color="primary"
                  class="mm-compact"
                  [href]="filmFranceSubscriptionLinks.worker"
                >
                  {{ 'mm.shared.layout-split.film-france.subscribeAsWorker' | translate }}
                </a>
                <a
                  [mmMixpanelTrack]="'Redirection Recherche des professionnels'"
                  [mixpanelTrackingType]="'link'"
                  mat-raised-button
                  color="primary"
                  class="mm-compact"
                  [href]="filmFranceSubscriptionLinks.talents"
                >
                  {{ 'mm.shared.layout-split.film-france.subscribeAsEmployer' | translate }}
                </a>
              </div>
            </div>
          } @else {
            <div class="movinmotion-container">
              <mm-tooltip-advertising></mm-tooltip-advertising>
            </div>
          }
        </div>
      </div>
    </div>
  } @else {
    <mm-loading></mm-loading>
  }
} @else {
  <mm-loading></mm-loading>
}
