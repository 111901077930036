import { Component, OnInit } from '@angular/core';
import { THEMES as TooltipThemes } from '../../shared/components/tooltip-card/tooltip-card.component';
import { AuthService } from '../../core/services/auth.service';
import { first } from 'rxjs/operators';
import { MovinmotionApplication } from '../../shared/models/environment';
import { environment } from '../../../environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppDisplayService } from '../../core/services/app-display.service';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'mm-page-select-app',
  templateUrl: './page-select-app.component.html',
  styleUrls: ['./page-select-app.component.scss'],
  standalone: false,
})
export class PageSelectAppComponent implements OnInit {
  /**
   * Select theme for blue tooltip
   */
  tooltipTheme = TooltipThemes.blue;

  /**
   * Access from the template to the Movinmotion application names
   */
  apps = MovinmotionApplication;

  /**
   * Handle loading state during select app
   */
  loading = false;

  constructor(
    public auth: AuthService,
    public appDisplay: AppDisplayService,
    public translate: TranslateService,
  ) {}

  ngOnInit() {
    this.appDisplay
      .setTitle$(this.translate.stream(_('mm.pages.select-app.title.head')))
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  /**
   * Redirect the user with the appropriate URL for the provided Movinmotion application
   */
  select(app: MovinmotionApplication) {
    this.loading = true;
    this.auth
      .redirect$(environment.movinmotionAppUrls[app])
      .pipe(first())
      .subscribe(ended => {
        if (!ended) {
          this.loading = false;
        }
      });
  }
}
